import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import authApi from "../../api/authApi";

import "./style.scss";

const REQUIRED_MESSAGE = "* Please enter your Username and Password";

export default function Login() {
  const userNameRef = useRef(null);
  const passwordRef = useRef(null);
  const otpRef = useRef(null);
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const userName = userNameRef.current.value;
    const password = passwordRef.current.value;

    if (!userName || !password) {
      setError(REQUIRED_MESSAGE);
      return;
    }

    try {
      const payload = {
        username: userName,
        password: password,
      };

      const res = await authApi.login(payload);
      localStorage.setItem("token", res.jwt);
      localStorage.setItem(
        "permissions",
        JSON.stringify(res.payload.permissions)
      );
      localStorage.setItem(
        "phone_number",
        JSON.stringify(res.payload.Phone)
      );
      navigate("/");

      // setStep(2);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmitOTP = () => {
    const otp = otpRef.current.value;

    // if (!otp) {
    //   setError("Please enter your OTP code");
    //   return;
    // }
    // const permission = ["check-voucher"];
    // navigate("/", { permission });
  };

  return (
    <div className="login">
      <div className="login-box">
        <h1 className="login-heading">Đăng nhập</h1>

        {step === 1 && (
          <div className="form">
            <div className="form-item">
              <input
                type="text"
                className="form-input"
                placeholder="Tên người dùng"
                ref={userNameRef}
              />
              <p className="form-error"></p>
            </div>
            <div className="form-item">
              <input
                type="password"
                className="form-input"
                placeholder="Mật khẩu"
                ref={passwordRef}
              />
            </div>
            <div className="form-error">{error}</div>

            <div className="form-btn">
              <button className="btn" onClick={handleSubmit}>
                Đăng nhập
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="form">
            <div className="form-item">
              <span className="form-label">Enter your OTP</span>
              <input type="text" className="form-input" ref={otpRef} />
            </div>
            <div className="form-error">{error}</div>

            <div className="form-btn">
              <button className="btn" onClick={handleSubmitOTP}>
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
