import instance from "./config";
import FileDownload from 'js-file-download';
import moment from 'moment';

const dataReport = {
  orderList: async function (startUnix = 1, endUnix = 999999999999, branch = -1, bracnhName = 'all') {
    const url = `/api/report?startTimestamp=${startUnix}&endTimestamp=${endUnix}&store=${branch}`;
    const res = await instance.get(url, {
      responseType: 'blob', // important
    });

    const start = moment(startUnix * 1000);
    const end = moment(endUnix * 1000);

    const fileName = `ARABICA_SALE_REPORT_${start.date()}-${end.date()}-${bracnhName}.xlsx`;
    FileDownload(res, fileName);
  },

  inventoryStock: async function (startUnix = 1, endUnix = 999999999999, branch = -1, bracnhName = 'all') {
    const url = `/api/report_inventory?startTimestamp=${startUnix}&endTimestamp=${endUnix}&store=${branch}`;
    const res = await instance.get(url, {
      responseType: 'blob', // important
    });

    const start = moment(startUnix * 1000);
    const end = moment(endUnix * 1000);

    const fileName = `%_Inventory_REPORT_${start.date()}-${end.date()}-${bracnhName}.xlsx`;
    FileDownload(res, fileName);
  },
  endShift: async function (payload = {}) {
    const res = await instance.post('/api/end_shift', { ...payload });
    return res;
  },
  vatRequestReport: async function (startUnix = 1, endUnix = 999999999999, clientId = 0) {
    let url = `/api/report_vat_request?startTimestamp=${startUnix}&endTimestamp=${endUnix}`;
    if (clientId) {
      url += `&clientId=${clientId}`
    }
    const res = await instance.get(url, {
      responseType: 'blob', // important
    });
    const fileName = `%ARABICA_VAT_REQUEST.xlsx`;
    FileDownload(res, fileName);
  },
  CustomReport: async function (startUnix, endUnix, storeId, reportId, reportName) {
    const url = `/api/custom_report?startTimestamp=${startUnix}&endTimestamp=${endUnix}&storeId=${storeId}&reportId=${reportId}`;
    const res = await instance.get(url, {
      responseType: 'blob', // important
    });
    const fileName = `%ARABICA_${reportName}-${new Date().getTime()}.xlsx`;
    FileDownload(res, fileName);
  },
  GetCash: async function (dataPayload = {}) {
    const url = `/api/get_cash`;
    const res = await instance.post(url, dataPayload, {
      responseType: 'blob', // important
    });
    const fileName = `%ARABICA_cash-${new Date().getTime()}.docx`;
    FileDownload(res, fileName);
  }
};


export default dataReport;
