import moment from 'moment';
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "../../components/Select";
import Table from "../../components/Table";
import metaData from '../../api/metaData';
import dataReport from '../../api/report';

import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

const data = [];
const columns = [
  {
    header: "Name",
    accessor: "userName",
  },
  {
    header: "Export Date",
    accessor: "exportAt",
  },
  {
    header: "IP Address",
    accessor: "ipAddress",
  },
];
export default function ExportData() {
  const [startDate, setStartDate] = useState(moment().startOf('date').toDate());
  const [reportList, setReportList] = useState([{
    value: -1,
    label: 'Sale report'
  }, {
    value: 1,
    label: 'Inventory Stock Report'
  }, {
    value: 2,
    label: 'VAT Request Report'
  }]);

  const [endDate, setEndDate] = useState(moment().endOf('date').toDate());
  const [branch, setBranch] = useState({ label: 'Tất cả chi nhánh', value: -1 });
  const [type, setType] = useState({ label: 'Sale report', value: -1 });

  const [branchOptionList, setBranchOptionList] = useState([]);
  const [isLoad, setIsload] = useState(false);
  function exportData() {
    setIsload(true);
    const startTimestamp = moment(startDate).unix();
    const endTimestamp = moment(endDate).unix();
    const client = branch?.value || -1;

    if (type.value === -1) {
      dataReport.orderList(startTimestamp, endTimestamp, client, branch.label).then(res => {
        setIsload(false);
      }).catch(err => {
        alert('Đã có lỗi xảy ra, vui lòng thử lại.');
        setIsload(false);
      });
    }
    if (type.value === 1) {
      return dataReport.inventoryStock(startTimestamp, endTimestamp, client, branch.label).then(res => {
        setIsload(false);
      }).catch(err => {
        alert('Đã có lỗi xảy ra, vui lòng thử lại.');
        setIsload(false);
      });
    }
    if (type.value === 2) {
      return dataReport.vatRequestReport(startTimestamp, endTimestamp).then(res => {
        setIsload(false);
      }).catch(err => {
        alert('Đã có lỗi xảy ra, vui lòng thử lại.');
        setIsload(false);
      });
    }
    if (type.value > 100) {
      return dataReport.CustomReport(startTimestamp, endTimestamp, client, type.value, type.label).then(res => {
        setIsload(false);
      }).catch(err => {
        alert('Đã có lỗi xảy ra, vui lòng thử lại.');
        setIsload(false);
      });
    }
  }

  useEffect(() => {
    metaData.getStoreList().then(res => {
      setBranchOptionList(res);
    });
    metaData.getReportList().then(res => {
      setReportList([...reportList, ...res]);
    })
  }, []);


  return (
    <div className="export">
      <div className="title">Export Data</div>

      <div className="content">
        <div className="form">
          <div className="row">
            <div className="form-item">
              <span className="form-label">Thời gian bắt đầu</span>
              <div className="form-date">
                <DatePicker
                  showTimeSelect
                  timeIntervals={5}
                  timeFormat="HH:mm"
                  dateFormat="Pp"
                  withPortal
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Select End Date"
                />
              </div>
            </div>
            <div className="form-item">
              <span className="form-label">Thời gian kết thúc</span>
              <div className="form-date">
                <DatePicker
                  showTimeSelect
                  timeIntervals={5}
                  timeFormat="HH:mm"
                  dateFormat="Pp"
                  withPortal
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="Select Start Date"
                />
              </div>
            </div>

            <div className="form-item">
              <span className="form-label">Chọn chi nhánh</span>
              <Select
                value={{ label: 'Tất cả chi nhánh', value: -1 }}
                onChange={(option) => setBranch(option)}
                selected={branch}
                options={branchOptionList}
                placeholder="Select a branch ..."
              />
            </div>
            <div className="form-item">
              <span className="form-label">Loại báo cáo</span>
              <Select
                value={{ label: 'Sale report', value: -1 }}
                onChange={(option) => setType(option)}
                selected={type}
                options={reportList}
                placeholder="Select a branch ..."
              />
            </div>
          </div>

          <div className="form-btn">
            <button className="btn" onClick={() => { exportData() }}>Xuất báo cáo</button>
            {isLoad && <div className="loader" style={{ marginLeft: '20px' }}></div>}
          </div>
        </div>
      </div>
    </div>
  );
}
