import { NumericFormat } from "react-number-format";
import Table from "../../../components/Table";
import { useState } from 'react';

export default function Cash({ cashData, handleChangeCash, setState, textView = 'Tiền mặt' }) {
  const [total, setTotal] = useState(0);
  const handleQuantityChange = (qty, index, e) => {
    if (qty < 0) {
      qty = 0;
      e.target.value = 0;
    }
    const _data = [...cashData];
    const editRow = _data[index];

    _data[index] = {
      ...editRow,
      qty,
      subtotal: qty * Number(editRow.den)
    };
    console.log(_data);
    let total = 0;
    for (const item of _data) {
      total += item.subtotal;
    }

    total = total.toLocaleString('vi', { style: 'currency', currency: 'VND' });
    setTotal(total);
    handleChangeCash(_data);
  };

  const columns = [
    {
      header: "Mệnh giá (VND)",
      accessor: "den",
      Cell: ({ cell }) => (
        <NumericFormat
          thousandSeparator={true}
          value={cell}
          displayType="text"
        />
      )
    },
    {
      header: "Số tờ",
      accessor: "qty",
      Cell: ({ cell, index }) => (
        <input
          type="number"
          className="form-input"
          onBlur={(e) => handleQuantityChange(Number(e.target.value), index, e)}
          defaultValue={cell}
        />
      )
    },
    {
      header: "Tổng cộng (VND)",
      accessor: "subtotal",
      Cell: ({ cell }) => (
        <NumericFormat
          thousandSeparator={true}
          value={cell}
          displayType="text"
        />
      )
    }
  ];
  return (
    <>
      <div className="report-table">
        <h3 className="report-table-title">{textView}</h3>
        <h3 className="report-table-title">Tổng cộng: <span style={{ color: 'blue' }}>{total}</span></h3>
        <Table columns={columns} data={cashData} />
      </div>
    </>
  );
}
