import React, { useState, useEffect } from "react";

import { ReactComponent as ChevronDown } from "../../assets/chevron-down.svg";

import "./style.scss";

export default function Select({
  onChange,
  selected,
  options,
  width = 280,
  placeholder = "",
}) {
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleChange = (option) => {
    setOpenDropdown(false);
    if (onChange) {
      onChange(option);
    }
  };

  useEffect(() => {
    function callback(e) {
      if (!e.target.closest(".select") && openDropdown) {
        setOpenDropdown(false);
      }
    }

    window.addEventListener("click", callback);

    return () => {
      window.removeEventListener("click", callback);
    };
  }, [openDropdown]);

  return (
    <div className="select" style={{ width: width }}>
      <div className="select-value" onClick={handleToggleDropdown}>
        {selected && selected.label ? (
          <span>{selected?.label}</span>
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}

        <ChevronDown />
      </div>
      <div
        className="select-option-list"
        style={openDropdown ? { display: "block" } : {}}
      >
        {options.map((option) => (
          <div
            key={option.value}
            className="select-option"
            onClick={() => handleChange(option)}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}
