import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || (process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:4000'),
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  function (config) {
    if (config.url.match("login")) {
      return config;
    } else {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
      return config;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error.response ? error.response.data : error);
  }
);

export default instance;
