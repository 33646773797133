import React, { useMemo } from "react";

import "./style.scss";

export default function Table({ columns, data, title }) {
  return (
    <div className="table-wrap">
      {title && <h3 className="table-title">{title}</h3>}
      <div style={{ maxHeight: '1080px', minHeight: '100px' }}>
        <table className="table">
          <thead>
            <tr className="table-row">
              {columns.map((column, index) => (
                <th key={index} className="table-col">
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data.map((row, index) => {
              return (
                <tr key={index} className="table-row">
                  {columns.map((column, colIndex) => (
                    <td key={colIndex} className="table-col">
                      {column.Cell
                        ? column.Cell({
                          cell: row[column.accessor],
                          index,
                          original: row
                        })
                        : row[column.accessor]}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
