import instance from "./config";

const OTPAPI = {
  sendOTP: function () {
    const url = "/api/send-mail-otp";
    return instance.get(url);
  },
  validate: function (otp) {
    const url = `/api/validate-mail-otp?otp=${otp || '1234555'}`;
    return instance.get(url);
  }
};

export default OTPAPI;
