import { NumericFormat } from "react-number-format";
import Table from "../../../components/Table";
import { useState } from 'react';

export default function GetCash({ cashData, setState, textView = 'Tiền mặt' }) {
    function onCompletedInputCash(e) {
        const id = Number(e.target.id);
        let value = e.target.value;
        console.log(value);
        value = value.replaceAll(',', '');
        value = Number(value);
        if (!isNaN(value)) {
            const target = cashData.find(item => item.id === id);
            target.cash = Math.abs(value) || 0;
            setState(cashData);
            e.target.value = value.toLocaleString();
        } else {
            e.target.value = 0;
        }
    }
    function onChangeCash(e) {
        let value = e.target.value;
        value = value.replaceAll(',', '');
        value = Number(value);
        if (!isNaN(value)) {
            e.target.value = value.toLocaleString();
        } else {
            e.target.value = 0;
        }
    }



    function onChangeNote(e) {
        const id = Number(e.target.id.replaceAll('note-', ''));
        const value = e.target.value;
        const target = cashData.find(item => item.id === id);
        target.note = value;
        console.log(target.note);
        setState(cashData);
    }

    const columns = [
        {
            header: "Ca Làm việc [Cash Input/Cash Actual]",
            accessor: "label",
            Cell: ({ cell }) => (cell)
        },
        {
            header: "Tiền mặt thực nhận",
            Cell: ({ cell, original }) => {
                return (
                    <input
                        id={original.id}
                        type="text"
                        className="form-input"
                        onBlur={onCompletedInputCash}
                        onChange={onChangeCash}
                    />
                )
            }
        },
        {
            header: "Ghi Chú",
            Cell: ({ cell, original }) => {
                const id = `note-${original.id}`;
                return (
                    <input
                        id={id}
                        type="text"
                        className="form-input"
                        onBlur={onChangeNote}
                        defaultValue={cell}
                    />
                )
            }
        }
    ];
    return (
        <>
            <div className="report-table">
                <h3 className="report-table-title">{textView}</h3>
                <Table columns={columns} data={cashData} />
            </div>

        </>
    );
}
