import { NumericFormat } from "react-number-format";
import Table from "../../../components/Table";
import Autocomplete from '../AutoComplete';
import { useState, useEffect } from 'react';
import metaData from '../../../api/metaData';

export default function RequestChange({ requestData, setRequestChange }) {
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    metaData.staffOrders().then(res => {
      setSuggestions(res);
    });
  }, []);

  const columns = [
    {
      header: "Mã đơn hàng",
      Cell: (cell) => (<Autocomplete targetCell={cell} suggestions={suggestions} requestData={requestData} />)
    },
    {
      header: "Điều chỉnh",
      Cell: (cell) => {
        return (
          <select
            id={`selected_${cell.original.id}`}
            className="select-box" // Add CSS class here
            value={cell?.original?.change || ''}
            onChange={(e) => {
              const newData = requestData.map(item =>
                item.id === cell?.original?.id
                  ? { ...item, change: e.target.value }
                  : item
              );
              setRequestChange(newData);
            }}
          >
            <option value="cancel">Hủy đơn hàng</option>
          </select>
        )
      }
    },
    {
      header: "Ghi chú",
      Cell: (cell) => (
        <input
          onChange={(e) => {
            const noteValue = e.target.value;
            cell.original.note = noteValue;
          }}
        />
      )
    },
    {
      header: "",
      Cell: (cell) => {
        return (
          <button className="btn" style={{ backgroundColor: 'white', color: 'black', width: '100%' }} onClick={() => {
            const id = cell?.original?.id;
            const data = requestData.filter(item => item?.id !== id);
            setRequestChange(data);
          }}>
            REMOVE
          </button>
        );
      }
    }
  ];

  function addNewRequestChange() {
    const item = {
      id: new Date().getTime(),
      code: '',
      change: 'cancel',
      note: ''
    }
    const newData = [...requestData, item];
    setRequestChange(newData);
  }

  return (
    <>
      <div className="report-table" style={{ marginTop: '30px', marginBottom: '50px' }}>
        <h3 className="report-table-title">Yêu cầu hiệu chỉnh đơn hàng</h3>
        <Table columns={columns} data={requestData} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <button className="btn" onClick={addNewRequestChange}>
            Thêm yêu cầu điều chỉnh
          </button>
        </div>
      </div >
    </>
  );
}
