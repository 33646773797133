import React, { useMemo, useRef } from "react";
import "./style.scss";
import Table from '../Table';
import moment from 'moment';
import { NumericFormat } from "react-number-format";

const columns = [
  {
    header: "Phương thức thanh toán",
    accessor: "method",
    Cell: ({ cell }) => (
      <div>{cell}</div>
    )
  },
  {
    header: "Đã nhập",
    accessor: "input",
    Cell: ({ cell, index }) => (
      <NumericFormat
        thousandSeparator={true}
        value={cell}
        displayType="text"
      />
    )
  },
  {
    header: "Thực tế",
    accessor: "actual",
    Cell: ({ cell }) => (
      <NumericFormat
        thousandSeparator={true}
        value={cell}
        displayType="text"
      />
    )
  },
  {
    header: "Chênh lệch",
    accessor: "wrong",
    Cell: ({ cell }) => {
      let color = 'black';

      if (cell > 0) {
        color = 'green'
      } else if (cell < 0) {
        color = 'red';
      }

      return (
        <div style={{ color: color }}>
          <NumericFormat
            thousandSeparator={true}
            value={cell}
            displayType="text"
          />
        </div>
      )
    }
  }
];

export default function ResultMoal({ payload, resultData, handleClose, message }) { // {meta,data}
  console.log(payload);

  return (
    <div className="result-modal-wrap">
      <div className="result-modal">
        <h2 style={{ textAlign: "center", paddingTop: '20px' }}>THÔNG TIN KẾT CA</h2>
        <h3 style={{ textAlign: "center", paddingTop: '20px', color: 'red' }}>{message}</h3>

        <div className="result-modal-body">
          <table>
            <tr>
              <td><strong>Thời gian bắt đầu:</strong></td>
              <td>{moment(payload.startTimestamp * 1000).format('DD/MM/YYYY   h:MM:A')}</td>
            </tr>
            <tr>
              <td><strong>Thời gian kết thúc</strong></td>
              <td>{moment(payload.endTimestamp * 1000).format('DD/MM/YYYY   h:MM:A')}</td>
            </tr>
            <tr>
              <td><strong>Chi nhánh:</strong></td>
              <td>{payload.storeName || {}}</td>
            </tr>
            <tr>
              <td><strong>Nhân viên thực hiện:</strong></td>
              <td>{localStorage.getItem('current_user') || ''}</td>
            </tr>
            <tr>
              <td><strong>Hình thức chốt ca:</strong></td>
              <td>{payload.endOptions?.label}</td>
            </tr>
            <tr>
              <td><strong>Tiền mặt trả lại cửa hàng:</strong></td>
              <td>{(payload.cashReturn || 0).toLocaleString()}</td>
            </tr>
            <tr>
              <td><strong>Thời gian thực hiện: </strong></td>
              <td>{moment().format('DD/MM/YYYY   h:MM:A')}</td>
            </tr>
          </table>

          <div style={{ margin: '10px', fontSize: '24px', color: 'green' }}><strong>KẾT QUẢ KIỂM TRA:</strong> </div>

          <Table
            columns={columns}
            data={resultData}
          />
        </div>
        <div className="result-modal-footer">
          <button onClick={handleClose}>Đóng</button>
        </div>
      </div>
    </div>
  );
}
