import { useState } from "react";

export default function Card({ cardData, handleChangeCard, setState }) {
  const [cardValue, setCardValue] = useState(0);

  return (
    <div className="card-report">
      <h3>Thanh toán thẻ</h3>
      <div>Nhập số tiền trên mỗi hóa đơn, phân cách nhau bằng dấu cách hoặc xuống dòng.</div>
      <div className="form">
        <textarea
          cols="30"
          rows="10"
          value={cardData}
          onChange={(e) => {
            const textValue = e.target.value || '';
            const text = textValue.replaceAll('\n', ' ');
            let total = 0;
            const numbers = text.split(' ').map(item => {
              const num = Number(item);
              if (num > 0) {
                total += num;
                return num;
              }
            });
            handleChangeCard(e.target.value);
            setCardValue(total);
          }}
        ></textarea>
        <div>Tổng cộng: {cardValue.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}</div>
        <span className="note"></span>
      </div>
    </div>
  );
}
