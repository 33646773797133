import moment from 'moment';
import { useState, useEffect } from "react";
import metaData from '../../api/metaData';
import reportAPI from '../../api/report';

import { denominationList } from "../../helpers/constants";
import Select from "../../components/Select";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import DatePicker from "react-datepicker";
import GetCash from './GetCash';

export default function GetCashHome() {
  const [branch, setBranch] = useState(0);
  const [sender, setSender] = useState('');
  const [receive, setReceive] = useState('');
  const [date, setDate] = useState(moment().toDate());
  const [branchOptionList, setBranchOptionList] = useState([]);
  const [listShift, setListShift] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const receiveName = [{
    value: 'LÊ THỊ TUYẾT HƯƠNG',
    label: 'LÊ THỊ TUYẾT HƯƠNG'
  }, {
    value: 'ĐỖ PHẠM THU PHƯƠNG',
    label: 'ĐỖ PHẠM THU PHƯƠNG'
  }, {
    value: 'TRẦN HỒNG QUÂN',
    label: 'TRẦN HỒNG QUÂN'
  }];

  useEffect(() => {
    metaData.getStoreList(true).then(res => {
      setBranchOptionList(res.filter(item => !["164595", -1].includes(item.value)));
    });

  }, []);
  useEffect(() => {
    onChangeOptions();
  }, [date, branch]);

  function onChangeOptions() {
    setIsLoad(false);
    metaData.getListShift(date, branch?.value || -1).then(res => {
      setListShift(res);
      setIsLoad(false);
    });
  }

  async function exportDocFile() {
    let errMsg = '';

    const payload = {
      clientId: branch?.value,
      clientName: branch.label,
      sender: sender,
      receive: receive?.value,
      date: date,
      shiftList: listShift.map(item => {
        if (item.cash === undefined) {
          errMsg = `Cần nhập đầy đủ tiền mặt thực nhận ở các ca làm việc.`;
        }
        return {
          id: item.id,
          value: item.cash || 0,
          note: item.note || '',
          seller: item.seller
        }
      })
    };


    setIsLoad(true);
    await reportAPI.GetCash(payload);
    setIsLoad(false);
  }


  return (
    <>
      <div className="end-shift">
        <div className="title">Thu Tiền Chi Nhánh</div>
        <div className="content">
          <div className="form">
            <div className="row">
              <div className="form-item">
                <span className="form-label">Chi nhánh</span>
                <Select
                  onChange={setBranch}
                  selected={branch}
                  options={branchOptionList}
                  placeholder="Chọn chi nhánh..."
                />
              </div>
              <div className="form-item">
                <span className="form-label">Bên giao</span>
                <input
                  value={sender}

                  onChange={(e) => {
                    setSender(e.target.value);
                  }}
                  placeholder="Họ & Tên"
                />
              </div>
              <div className="form-item">
                <span className="form-label">Bên nhận</span>
                <Select
                  onChange={(option) => {
                    setReceive(option);
                    console.log(date);
                  }}
                  selected={receive}
                  options={receiveName}
                  placeholder="Bên nhận"
                />
              </div>
              <div className="form-item">
                <span className="form-label">Date</span>
                <div className="form-date">
                  <DatePicker
                    selected={date}
                    withPortal
                    onChange={setDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ borderTop: '1px solid black', width: '100%', display: 'block' }}></div>
          <br></br>
          <div className="form">
            <div style={{ marginBottom: '20px' }}>
              <GetCash
                cashData={listShift}
                setState={setListShift}
                textView='Tiền mặt theo ca làm việc'
              />
            </div>
          </div>
          <br />
          <br />
          <div>
            <div style={{ whiteSpace: 'nowrap' }}>
              <button className="btn" style={{ display: 'inline-block', marginRight: '20px' }} onClick={exportDocFile}>Xuất báo cáo</button>
              {isLoad && <div className="loader" style={{ display: 'inline-block' }}></div>}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
<input type="text" />;
