import instance from "./config";
import moment from "moment";

const metaData = {
  getStoreList: async function (onlystaff = false, params) {
    let url = "/api/store_list";
    if (onlystaff) {
      url += `?only_staff=true`;
    }

    const res = await instance.get(url, params);
    return res.map(item => {
      return {
        value: item.id,
        label: item.name
      }
    })
  },
  getReportList: async function () {
    const res = await instance.get('/public/report_list');
    return res.map(item => {
      return {
        value: item.value,
        label: item.label
      }
    })
  },
  getListShift: async function (date, branchId) {
    const dtmFormat = moment(date).format('DD-MM-YYYY');

    const res = await instance.get(`/api/list_shift?date=${dtmFormat}&branchId=${branchId}`);
    return res?.listStaft?.map(item => {
      return {
        seller: item.seller,
        id: item.value,
        value: item.value,
        label: item.label
      }
    }) || [];
  },
  staffOrders: async function () {
    const url = `/api/list_staff_order`;
    const res = await instance.get(url);
    return res?.results?.map(item => {
      return item.name;
    }) || [];
  }
};

export default metaData;
