
export const denominationList = [
  500000, 200000, 100000, 50000, 20000, 10000, 5000, 2000, 1000, 500
];

export const navLinks = {
  export: "Export Data",
  end_shift: "Kết ca",
  get_cash: 'Thu Tiền Chi Nhánh'
};
