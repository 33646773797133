import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { ReactComponent as MenuIcon } from "../../assets/list.svg";
import { navLinks } from "../../helpers/constants";

import "./style.scss";

export default function Nav() {
  const [openMenu, setOpenMenu] = useState(false);

  let permissions = JSON.parse(localStorage.getItem("permissions")) || [];
  permissions = permissions.filter(item => item !== 'voucher');

  const location = useLocation();

  useEffect(() => {
    setOpenMenu(false);
  }, [location.pathname]);

  return (
    <div className="nav">
      <div className="nav-mobile-icon" onClick={() => setOpenMenu(!openMenu)}>
        <MenuIcon />
      </div>
      <div className="nav-logo">%Arabica</div>

      <div className={`nav-list ${openMenu ? "nav-open" : ""}`}>
        <ul className="nav-menu">
          {permissions.map((permission) => (
            <li className="nav-item" key={permission}>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`/${permission}`}
              >
                {navLinks[permission]}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="nav-bottom">
          <button onClick={() => {
            localStorage.clear();
            window.location = '/';
          }}>Logout</button>

        </div>
      </div>
    </div>
  );
}
