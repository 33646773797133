import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Login from "./containers/Login";
import CheckVoucher from "./containers/CheckVoucher";
import ExportData from "./containers/ExportData";
import Nav from "./containers/Nav";

import "./app.scss";
import EndShift from "./containers/EndShift";
import GetCash from "./containers/GetCash";

import AuthenticatedRoute from "./containers/AuthenticatedRoute";

export default function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <AuthenticatedRoute
              component={EndShift}
              expectPermission="end_shift"
            />
          }
        />
        <Route
          path="/export"
          element={
            <AuthenticatedRoute
              component={ExportData}
              expectPermission="export"
            />
          }
        />
        <Route
          path="/get_cash"
          element={
            <AuthenticatedRoute
              component={GetCash}
              expectPermission="get_cash"
            />
          }
        />
        <Route
          path="/end_shift"
          element={
            <AuthenticatedRoute
              component={EndShift}
              expectPermission="end_shift"
            />
          }
        />
      </Routes>
    </>
  );
}
