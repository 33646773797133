// index.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './style.scss';

export default function Autocomplete({ targetCell = {}, suggestions = [], id = 0, requestData = [] }) {

    const [query, setQuery] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const handleInputChange = (e) => {
        const userInput = e.target.value;
        setQuery(userInput);
        if (userInput) {
            const selectedCode = requestData.map(item => item.code).filter(code => code);
            const filtered = [];
            for (const item of suggestions) {
                if (selectedCode.includes(item)) continue;
                if (item.toLowerCase().includes(userInput.toLowerCase())) {
                    filtered.push(item);
                    break;
                }
            }
            setQuery(userInput);
            targetCell.original.code = userInput;
            setFilteredSuggestions(filtered);
        } else {
            setFilteredSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion);
        targetCell.original.code = suggestion;
        setFilteredSuggestions([]);
    };

    const handleOutsideClick = (e) => {
        if (!e.target.closest('.autocomplete-container')) {
            setFilteredSuggestions([]);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className="autocomplete-container">
            <input
                id={`order_code_${id}`}
                type="text"
                value={query}
                onChange={handleInputChange}
            
                placeholder="Type to search..."
                className="autocomplete-input"
            />
            {filteredSuggestions.length > 0 && (
                <div className="suggestion-list">
                    {filteredSuggestions.map((item, index) => (
                        <div key={index} className="suggestion-item" onClick={() => handleSuggestionClick(item)}>
                            {item}
                            <br></br>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
