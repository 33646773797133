import React from "react";
import Nav from "../Nav";

export default function AuthenticatedRoute({
  component: Component,
  expectPermission,
}) {
  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];
  
  return (
    <div className="app">
      <div className="nav-wrap">
        <Nav />
      </div>
      <div className="main">
        {permissions.indexOf(expectPermission) >= 0 ? (
          <Component />
        ) : (
          <h1>Bạn không có quyền truy cập.</h1>
        )}
      </div>
    </div>
  );
}
