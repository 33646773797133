import instance from "./config";

const authApi = {
  login: function (params) {
    const url = "/public/auth/login";
    return instance.post(url, params);
  },
};

export default authApi;
