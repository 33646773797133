import jwtDecode from 'jwt-decode';
import React, { useMemo, useRef, useEffect, useState } from "react";
import { format } from "date-fns";
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { NumericFormat } from "react-number-format";
import "./style.scss";
import report from '../../api/report';
import OTP from '../../api/OTP';
import moment from 'moment';
import ResultModal from '../ResultModal';

const app = initializeApp({
  apiKey: "AIzaSyDUi4uAVqEpG4hyTBNqoEZvQEGP9ZSue8g",
  authDomain: "arabica-worker.firebaseapp.com",
  projectId: "arabica-worker",
  storageBucket: "arabica-worker.appspot.com",
  messagingSenderId: "474953153749",
  appId: "1:474953153749:web:70885fdf6b6315c94bf65c",
  measurementId: "G-PYGR5N3GTT"
});

const auth = getAuth(app);
auth.languageCode = 'vi';


export default function Modal({ payload, handleClose, handleSubmit, result }) {
  const [otp, setOTP] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const optRef = useRef(null);
  const cashTotal = payload.cashData.reduce(
    (total, item) => (total += item.subtotal),
    0
  );
  const cashReturnTotal = payload.cashReturnData.reduce(
    (total, item) => (total += item.subtotal),
    0
  );

  const [openResultModal, setOpenResult] = useState(false);
  const [dataResult, setDataResult] = useState([]);

  const cardTotal = useMemo(() => {
    if (payload.cardData) {
      const textValue = payload.cardData;
      const text = textValue.replaceAll('\n', ' ');
      let total = 0;
      const numbers = text.split(' ').map(item => {
        const num = Number(item);
        if (num > 0) {
          total += num;
          return num;
        }
      });

      return total;
    }
    return 0;
  }, [payload.cardData]);

  const totalValue = Number(payload?.appData || 0) + Number(payload?.cardData || 0) + Number(cashTotal) + Number(payload.voucher);
  const token = localStorage.getItem('token');
  let jwtData = jwtDecode(token);
  localStorage.setItem('current_user', jwtData.Name);

  const [otpMessage, setOTPMessage] = useState('');
  let [confirmFunction, setConfirmFunction] = useState({
    confirm: () => Promise.resolve(false)
  });

  function sendOTP() {
    OTP.sendOTP().then(res => {
      if (res.message) {
        setResultMessage(res.message);
      }
    })
  }

  function checkOTP() {
    OTP.validate(otp).then(res => {
      if (res?.message === 'success') {
        report.endShift({
          startTimestamp: moment().startOf('day').unix(),
          endTimestamp: moment().endOf('day').unix(),
          storeId: payload?.branch?.value,
          cash: cashTotal,
          card: cardTotal,
          other: payload.appData,
          voucher: payload.voucher,
          cashReturn: cashReturnTotal,
          googleToken: '',
          single: payload?.endOptions?.value || false,
          requestChange: payload?.requestChange || []
        }).then(res => {
          if (res && res?.inputMoney && res?.actualMoney) {
            const result = [{
              method: 'Tiền mặt',
              input: res?.inputMoney.cash,
              actual: res?.actualMoney.cash,
              wrong: res?.inputMoney.cash - res?.actualMoney.cash
            }, {
              method: 'Quẹt thẻ',
              input: res?.inputMoney.card,
              actual: res?.actualMoney.card,
              wrong: res?.inputMoney.card - res?.actualMoney.card
            }, {
              method: 'Phương thức khác (Momo, Zalo...)',
              input: res?.inputMoney.other,
              actual: res?.actualMoney.other,
              wrong: res?.inputMoney.other - res?.actualMoney.other
            }, {
              method: 'Voucher',
              input: res?.inputMoney.voucher,
              actual: res?.actualMoney.voucher,
              wrong: res?.inputMoney.voucher - res?.actualMoney.voucher
            }];
            setDataResult(result);
          }
          setOpenResult(true);
        })
      } else {
        setOTPMessage('Mã xác thực không hợp lệ.');
      }
    }).catch(err => {
      setOTPMessage('Mã xác thực không hợp lệ.');
    });


  }

  useEffect(() => {
    setTimeout(() => {
      sendOTP();
    }, 1000);
  }, []);
  const mapChange = {
    cancel: 'Hủy đơn hàng'
  };

  return (
    <div className="modal-wrap">
      <div className="modal">
        <div className="modal-heading">
          <span>Xác nhận chốt ca</span>
        </div>
        <div className="modal-body">
          <p>
            Chi nhánh: <strong>{payload.branch?.label}</strong>
          </p>
          <p>
            Bắt đầu: <strong>{format(payload.startTime, "h:mm aa")}</strong>
          </p>
          <p>
            Kết thúc: <strong>{format(payload.endTime, "h:mm aa")}</strong>
          </p>
          <p>
            Hình thức chốt ca: <strong>{payload?.endOptions?.label}</strong>
          </p>

          <table>
            <tr>
              <td><strong>Tiền mặt đầu ca làm việc: </strong></td>
              <td>
                <NumericFormat
                  value={cashReturnTotal}
                  thousandSeparator={true}
                  displayType="text"
                />
              </td>
            </tr>
            <tr>
              <td><strong>Tổng tiền mặt: </strong></td>
              <td>
                <NumericFormat
                  value={cashTotal}
                  thousandSeparator={true}
                  displayType="text"
                />
              </td>
            </tr>
            <tr>
              <td><strong>Tổng tiền thanh toán qua thẻ ATM: </strong></td>
              <td>
                <NumericFormat
                  value={cardTotal}
                  thousandSeparator={true}
                  displayType="text"
                />
              </td>
            </tr>
            <tr>
              <td><strong>Tổng tiền thanh toán qua các phương thước khác: </strong></td>
              <td>
                <NumericFormat
                  value={payload.appData}
                  thousandSeparator={true}
                  displayType="text"
                />
              </td>
            </tr>
            <tr>
              <td><strong>Tổng tiền voucher: </strong></td>
              <td>
                <NumericFormat
                  value={payload.voucher}
                  thousandSeparator={true}
                  displayType="text"
                />
              </td>
            </tr>

          </table>
          <br />___________________________________________________________________
          <br />
          <strong>CÁC YÊU CẦU THAY ĐỔI TRƯỚC KHI THỰC HIỆN KẾT CA.</strong>
          <div style={{ maxHeight: '120px', overflowY: 'auto' }}>
            <table>
              {payload.requestChange.map(data => {
                return (
                  <tr>
                    <td><strong>{data.code}</strong></td>
                    <td>{mapChange[data.change]}</td>
                    <td>{data.note}</td>
                  </tr>
                );
              })}
            </table>
          </div>
          <div id='recaptcha-container'></div>
          <div className="enter-code">
            <span>{resultMessage}</span>
            <input className="input" type="text" onChange={(e) => {
              setOTP(e.target.value);
              console.log(otp);
            }} />
            {result && <span className="result">{result}</span>}
          </div>
          <p className="note">
            *Lưu ý: Chỉ được chốt ca 1 lần duy nhất trong ngày
          </p>
          <p>{otpMessage}</p>
        </div>

        <div className="modal-footer">
          <button onClick={handleClose}>Đóng</button>
          <button
            className="btn"
            onClick={() => {
              checkOTP();
            }}
          >
            Xác nhận
          </button>
        </div>
      </div>
      {openResultModal && (
        <ResultModal
          payload={{
            startTimestamp: moment(payload.startTime).unix(),
            endTimestamp: moment(payload.endTime).unix(),
            storeName: payload?.branch?.label || 'null',
            cash: cashTotal,
            card: cardTotal,
            other: payload.appData,
            voucher: payload.voucher,
            ...payload
          }}
          resultData={dataResult}
          handleClose={() => setOpenResult(false)}
          handleSubmit={handleSubmit}
          result={result}
          message=''
        />
      )}
    </div>
  );
}
