import moment from 'moment';
import { useState, useEffect } from "react";
import metaData from '../../api/metaData';

import { denominationList } from "../../helpers/constants";

import DatePicker from "react-datepicker";
import { NumericFormat } from "react-number-format";

import Modal from "../../components/Modal";
import Select from "../../components/Select";
import Card from "./Card";
import Cash from "./Cash";
import RequestChange from "./RequestChange";
import Autocomplete from './AutoComplete';

import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

const initialCashData = denominationList.map((den) => ({
  den: den,
  qty: 0,
  subtotal: 0
}));

const defaultStartTime = new Date().getTime() - 60 * 60 * 1000 * 10; // 10 hours before the current time
const optionData = [{
  label: 'Toàn bộ cửa hàng',
  value: false
}, {
  label: 'Chỉ chốt cho tài khoản hiện tại',
  value: true
}];

export default function EndShift() {
  const [openModal, setOpenModal] = useState(false);
  const [startTime, setStartTime] = useState(moment().startOf('date').toDate());
  const [endTime, setEndTime] = useState(moment().endOf('date').toDate());
  const [branch, setBranch] = useState(0);
  const [endOptions, setEndOption] = useState({
    label: 'Chỉ chốt cho tài khoản hiện tại',
    value: true
  });

  const [cashData, setCashData] = useState(initialCashData);
  const [cashReturnData, setCashReturnData] = useState(initialCashData);

  const [cardData, setCardData] = useState("");
  const [appData, setAppData] = useState(0);
  const [cashReturn, setcashReturn] = useState(0);
  const [voucher, setVoucher] = useState(0);
  const [result, setResult] = useState("");
  const [branchOptionList, setBranchOptionList] = useState([]);
  const [requestChange, setRequestChange] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  // ==================== State of child container ==================== 
  const [cashState, setCashState] = useState({});
  const [cardState, setCardState] = useState({});


  useEffect(() => {
    metaData.getStoreList(true).then(res => {
      setBranchOptionList(res);
    });
  }, []);
  useEffect(() => {
    metaData.staffOrders().then(res => {
      setSuggestions(suggestions);
    });
  }, []);

  const handleOpenModal = () => {
    const dataFilter = requestChange.filter(item => {
      return item?.change && item?.code;
    });

    let requestChangeValid = true;
    const todayCode = moment().format('DDMMYY').toString();
    for (const item of dataFilter) {
      if (!item.code || !item.code.includes(todayCode) || !item.code.includes('(') || !item.code.includes(')')) {
        requestChangeValid = false;
      }
    }


    if(!requestChangeValid) {
      return alert('Hãy kiểm tra lại các mã đơn hàng cần hủy. Thông tin mã đơn cần chọn từ danh sách.');
    }


    setRequestChange(dataFilter);
    const cashReturnTotal = cashReturnData.reduce(
      (total, item) => (total += item.subtotal),
      0
    );

    if (cashReturnTotal < 2000000) {
      return alert(`Không thể chốt ca làm việc khi số tiền mặt ban đầu < 2.000.000đ.`);
    }

    if (!branch?.value) {
      return alert('Chọn chi nhánh để thực hiện chốt ca.');
    }

    setOpenModal(true);
  };

  const handleSubmit = (otp) => {
    setResult("");

    if (otp !== "otp_123456") {
      setResult("OTP không hợp lệ");
    }
  };

  return (
    <>
      <div className="end-shift">
        <div className="title">Chốt ca</div>

        <div className="content">
          <div className="form">
            <div className="row">
              <div className="form-item">
                <span className="form-label">Chọn chi nhánh</span>
                <Select
                  onChange={(option) => setBranch(option)}
                  selected={branch}
                  options={branchOptionList}
                  placeholder="Chọn chi nhánh..."
                />
              </div>
            </div>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <Cash
              cashData={cashReturnData}
              handleChangeCash={(data) => setCashReturnData(data)}
              setState={setCashReturnData}
              textView='Tiền mặt có sẵn đầu ca làm việc (Nhập tổng cộng >= 2.000.000đ)'
            />
          </div>

          <Cash
            cashData={cashData}
            handleChangeCash={(data) => setCashData(data)}
            setState={setCashState}
            textView='Tiền mặt đã thu trong ca làm việc'
          />
          <br />
          <Card
            cardData={cardData}
            handleChangeCard={(data) => setCardData(data)}
            setState={setCardState}
          />

          <h3>Tổng số tiền thanh toán qua các phương thức khác</h3>
          <NumericFormat
            value={appData}
            thousandSeparator={true}
            onValueChange={({ floatValue }) => setAppData(floatValue)}
          />

          <h3>Tổng số voucher</h3>
          <NumericFormat
            value={voucher}
            thousandSeparator={true}
            onValueChange={({ floatValue }) => setVoucher(floatValue)}
          />
          <br />
          <RequestChange
            requestData={requestChange}
            setRequestChange={setRequestChange}
          />

          <div className="submit-btn">
            <button className="btn" onClick={handleOpenModal}>
              Chốt ca
            </button>
          </div>
        </div>
      </div>

      {/* ======== */}
      {openModal && (
        <Modal
          payload={{
            startTime,
            endTime,
            branch,
            cashData,
            appData,
            cardData,
            cashReturnData,
            voucher,
            endOptions,
            requestChange
          }}
          handleClose={() => setOpenModal(false)}
          handleSubmit={handleSubmit}
          result={result}
        />
      )}
    </>
  );
}
<input type="text" />;
